import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const Katalog19 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Friedrich Hölderlin: Der Sommer" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Friedrich Hölderlin: Der Sommer",
                link: "/k/19",
              },
            ]}
          />
          <PageTitle>Friedrich Hölderlin: Der Sommer</PageTitle>
          <Constrain>
            <AudioPlayer
              src="katalog/19-christian-reiner-der-sommer"
              title="Friedrich Hölderlin: Der Sommer – gelesen von Christian Reiner"
            />
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog19
